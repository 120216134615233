
.bg-c-blue {
    background: linear-gradient(45deg,#4469d1,#4469d1);
}

.bg-c-purple {
    background: linear-gradient(45deg,#7b1fa2,#7b1fa2);
}

.bg-c-yellow {
    background: linear-gradient(45deg,#ff6f00,#ff6f00);
}

.bg-c-pink {
    background: linear-gradient(45deg,#ff5252,#ff5252);
}

.bg-c-green {
    background: linear-gradient(45deg,#249651,#249651);
}

.p-rating .p-rating-icon.pi-star-fill {
    color: #FFD700;
}

.p-rating:hover, .p-rating:active,.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
    color: #FFD700 !important;
}

.card.charts {
    position: relative;
    .spinnerloader {
        padding: 0;
        margin: 0;
        position: absolute;
        width: 95%;
        height: 80%;
        background-color: rgb(0, 0, 0, 0.2);
    }
}