.login-container {
    display: flex;
    flex-grow: 1;
    min-height: 100vh;
  }
  
.login-transparent-layer {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}

.login-message-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 5%;
  background-color: #f8f9fa;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-message-container > h1 {
  font-size: 2rem;
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 0 30%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-logo {
  margin: 5rem 0;
  /* height: 5rem; */
  /* width: 12.5rem; */
  align-self: center;
}

.login-form-col {
  display: flex;
  flex-direction: column;
}

.login-form-card {
  min-width: 400px;
  margin-bottom: 1rem;
  padding-inline: 3rem;
}

.login-form-card > h2 {
  margin-bottom: 2rem;
}

.p-field {
  margin-bottom: 2rem;
}

.login-form-buttons-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 1rem;
}

.p-card .p-card-content {
  padding: 0;
}

.p-card .p-card-body {
  padding: 1.5rem;
}

@media screen and (max-width: 1024px) {
  .login-transparent-layer {
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .login-message-container {
    justify-content: center;
    align-items: center;
  }

  .login-form-container {
    display: none;
    justify-content: flex-start;
    align-items: center;
  }

  .login-message-container > p {
    display: none;
  }

  .login-logo {
    margin: 2rem 0;
    align-self: center;
  }
}

@media screen and (max-width: 787px) {
  .login-form-card {
    min-width: 90%;
  }
}
